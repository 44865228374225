import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  HStack,
  ModalCloseButton,
  ModalFooter,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import Controls from '../Controls/Controls';
import { ModalProps, modalPadding } from './Modal';
import ModalOverlay from './ModalOverlay';

const ModalMobile: FC<ModalProps> = ({
  children,
  header = '',
  isOpen,
  negativeButtonHandler,
  positiveButtonText,
  onClose,
  positiveButtonHandler,
  negativeButtonText,
  showCloseButton = false,
  negativeButtonStyles,
  positiveButtonStyles,
  width,
  withPadding = true,
  containerProps,
}) => {
  const showModalFooter =
    (positiveButtonText && positiveButtonHandler) || (negativeButtonText && negativeButtonHandler);

  const showHeader = header || showCloseButton;

  return (
    <Drawer
      isOpen={isOpen}
      placement='bottom'
      onClose={onClose}
    >
      <ModalOverlay />
      <DrawerContent
        maxH='100dvh'
        maxW='full'
        overflow='hidden'
        transition='max-height 0.3s ease-in-out'
        w={width}
        {...containerProps}
      >
        {showHeader && (
          <DrawerHeader px={withPadding ? modalPadding.px : 0}>
            <HStack justifyContent='space-between'>
              {header && <Text>{header}</Text>}
              {showCloseButton && <ModalCloseButton position='unset' />}
            </HStack>
          </DrawerHeader>
        )}
        <DrawerBody
          pb={withPadding ? modalPadding.py : 0}
          pt={!showHeader && withPadding ? modalPadding.py : 0}
          px={withPadding ? modalPadding.px : 0}
        >
          {children}
        </DrawerBody>

        {showModalFooter && (
          <Controls
            containerProps={{ as: ModalFooter, px: { base: 'md', md: 'lg' } }}
            negativeButtonStyle={negativeButtonStyles}
            negativeText={negativeButtonText}
            positiveButtonStyle={positiveButtonStyles}
            positiveText={positiveButtonText}
            onNegativeClick={negativeButtonHandler}
            onPositiveClick={positiveButtonHandler}
          />
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default ModalMobile;

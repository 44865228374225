import { Box, ButtonProps, Highlight, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { configModel } from '@/entities/Config';
import { Modal } from '@/sharedUI';

const ConfirmPopup = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { closeConfirmPopup } = configModel.useStoreMethods();
  const confirmPopup = configModel.useStore((s) => s.coreConfig.confirmPopup);

  const isOpen = !!confirmPopup;

  const handleConfirm: ButtonProps['onClick'] = async (e) => {
    setIsLoading(true);

    await confirmPopup?.onConfirm?.(e);

    setIsLoading(false);
    closeConfirmPopup();
  };

  return (
    <Modal
      header={confirmPopup?.header}
      isOpen={isOpen}
      negativeButtonHandler={closeConfirmPopup}
      negativeButtonText={confirmPopup?.negativeButtonText}
      positiveButtonHandler={handleConfirm}
      positiveButtonStyles={{ isLoading, colorScheme: 'error' }}
      positiveButtonText={confirmPopup?.positiveButtonText}
      onClose={closeConfirmPopup}
    >
      <VStack spacing={5}>
        <Text
          fontSize='lg'
          fontWeight='semibold'
          textAlign='center'
        >
          <Highlight
            query={confirmPopup?.messageHighlight || ''}
            styles={{ color: 'primary.base' }}
          >
            {confirmPopup?.message || ''}
          </Highlight>
        </Text>
        {confirmPopup?.secondText && (
          <Box
            fontSize='sm'
            textAlign='center'
          >
            {confirmPopup.secondText}
          </Box>
        )}
      </VStack>
    </Modal>
  );
};

export default ConfirmPopup;

import {
  Modal as ChakraModal,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import Controls from '../Controls/Controls';
import { ModalProps, modalPadding } from './Modal';
import ModalOverlay from './ModalOverlay';

const ModalDesktop: FC<ModalProps> = ({
  children,
  header = '',
  isOpen,
  negativeButtonHandler,
  positiveButtonText,
  onClose,
  positiveButtonHandler,
  negativeButtonText,
  showCloseButton = false,
  negativeButtonStyles,
  positiveButtonStyles,
  width = 'lg',
  withPadding = true,
  containerProps,
}) => {
  const showModalFooter =
    (!!positiveButtonText && !!positiveButtonHandler) ||
    (!!negativeButtonText && !!negativeButtonHandler);

  const showHeader = header || showCloseButton;

  return (
    <ChakraModal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        maxW='calc(100% - 2rem)'
        overflow='hidden'
        width={width}
        {...containerProps}
      >
        {showHeader && (
          <ModalHeader px={withPadding ? modalPadding.px : 0}>
            <HStack justifyContent='space-between'>
              <Text>{header}</Text>
              {showCloseButton && (
                <ModalCloseButton
                  justifySelf='flex-end'
                  position='relative'
                  right='-2xs'
                  top='-2xs'
                />
              )}
            </HStack>
          </ModalHeader>
        )}
        <ModalBody
          pb={withPadding ? modalPadding.py : 0}
          pt={!showHeader && withPadding ? modalPadding.py : 0}
          px={withPadding ? modalPadding.px : 0}
        >
          {children}
        </ModalBody>

        {showModalFooter && (
          <Controls
            containerProps={{ as: ModalFooter }}
            negativeButtonStyle={negativeButtonStyles}
            negativeText={negativeButtonText}
            positiveButtonStyle={positiveButtonStyles}
            positiveText={positiveButtonText}
            onNegativeClick={negativeButtonHandler}
            onPositiveClick={positiveButtonHandler}
          />
        )}
      </ModalContent>
    </ChakraModal>
  );
};

export default ModalDesktop;

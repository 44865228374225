import { ChakraProvider } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FC, ReactNode, createContext, useContext, useRef } from 'react';
import { authModel } from '@/entities/Auth';
import { profileClientModel } from '@/entities/Profile';
import { Loader } from '@/sharedUI';
import { IReviewsGroup, menuLinks as defaultMenuLinks, setTheme } from '@/sharedLib';
import { ILandingConfig } from '../types/landingConfig';
import { createConfigStore } from './configStore';

type TConfigStore = ReturnType<typeof createConfigStore>;

const ConfigStoreContext = createContext<TConfigStore | null>(null);

type TConfigStoreProviderProps = {
  children: ReactNode;
  config?: ILandingConfig;
  userSubdomain?: string;
  profileData?: any;
  menuLinks?: { name: string; link: string }[];
  reviews?: {
    google?: IReviewsGroup;
    yelp?: IReviewsGroup;
  };
  isMobile: boolean;
};

export const ConfigStoreProvider: FC<TConfigStoreProviderProps> = ({
  children,
  isMobile,
  config,
  menuLinks,
  profileData,
  reviews,
  userSubdomain,
}) => {
  const storeRef = useRef<TConfigStore>();

  const router = useRouter();
  const { subdomain: querySubdomain } = router?.query;
  let subdomain = userSubdomain || querySubdomain;
  const isEdit = !!router?.pathname?.endsWith('/edit');

  if (Array.isArray(subdomain)) {
    subdomain = subdomain[0] || '';
  }
  const { me, isLoading: isLoadingMe } = authModel.useGetMe({ pause: !isEdit });
  const { editConfig, isLoading: isLoadingEditConfig } = profileClientModel.useEditConfig({
    pause: !isEdit || !me,
  });
  const parsedConfig = config || editConfig;

  const isLoading = isEdit && (isLoadingEditConfig || isLoadingMe);

  if (isLoading) {
    return (
      <ChakraProvider theme={setTheme()}>
        <Loader />;
      </ChakraProvider>
    );
  }

  if (parsedConfig && !parsedConfig?.portfolio) {
    parsedConfig.portfolio = profileData?.portfolio?.map((item: any) => ({
      id: item?.id,
      title: item?.title,
      description: item?.description,
      price: item?.price,
      images: item?.images,
    }));
  }

  if (!storeRef.current) {
    // important to check if store exists, otherwise multiple store instances can be created
    const newStore = createConfigStore({
      config: { ...parsedConfig },
      editConfig: { ...parsedConfig },
      coreConfig: {
        isEdit,
        isMobile,
        menuLinks: menuLinks || defaultMenuLinks,
        subdomain: subdomain || null,
        confirmPopup: null,
        editModalOpen: null,
      },
      reviews,
    });

    storeRef.current = newStore;
  }

  return (
    <ConfigStoreContext.Provider value={storeRef.current}>{children}</ConfigStoreContext.Provider>
  );
};

export const useConfigStore = () => {
  const store = useContext(ConfigStoreContext);

  if (!store) {
    throw new Error('useProjectStore must be used within a StoreContext.Provider');
  }

  return store;
};

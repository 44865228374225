import { authExchange } from '@urql/exchange-auth';
import { retryExchange } from '@urql/exchange-retry';
import { createElement, useCallback, useMemo, useReducer } from 'react';
import { Provider, cacheExchange, fetchExchange, ssrExchange } from 'urql';
import { addAuthToOperation, didAuthError, getAuth } from './auth';
import { retryExchangeOptions } from './helpers';
import { initUrqlClient, resetClient } from './init-urql-client';

let ssr;

export function withUrqlClient(options = {}) {
  return (AppOrPage) => {
    const WithUrql = ({ pageProps, urqlClient, urqlState, ...rest }) => {
      const [version, forceUpdate] = useReducer((prev) => prev + 1, 0);
      const urqlServerState = (pageProps && pageProps.urqlState) || urqlState;

      const client = useMemo(() => {
        if (urqlClient && !version) {
          return urqlClient;
        }

        if (!ssr || typeof window === 'undefined') {
          // We want to force the cache to hydrate, we do this by setting the isClient flag to true
          ssr = ssrExchange({
            initialState: urqlServerState,
            isClient: true,
            staleWhileRevalidate:
              typeof window !== 'undefined' ? options.staleWhileRevalidate : undefined,
          });
        } else if (!version) {
          ssr.restoreData(urqlServerState);
        }

        const clientConfig = {
          exchanges: [
            cacheExchange,
            retryExchange(retryExchangeOptions),
            ssr,
            authExchange({
              getAuth,
              addAuthToOperation,
              didAuthError,
            }),
            fetchExchange,
          ],
        };

        return initUrqlClient(clientConfig);
      }, [urqlClient, urqlServerState, version]);

      const resetUrqlClient = useCallback(() => {
        resetClient();
        ssr = ssrExchange({ initialState: undefined });
        forceUpdate();
      }, []);

      return createElement(
        Provider,
        { value: client },
        createElement(AppOrPage, {
          ...rest,
          pageProps,
          urqlClient: client,
          resetUrqlClient,
        }),
      );
    };

    // Set the displayName to indicate use of withUrqlClient.
    const displayName = AppOrPage.displayName || AppOrPage.name || 'Component';
    WithUrql.displayName = `withUrqlClient(${displayName})`;

    if (AppOrPage.getLayout) {
      WithUrql.getLayout = AppOrPage.getLayout;
    }

    return WithUrql;
  };
}

export const units = {
  SERVICE: {
    name: 'service',
    title: 'for the service',
  },
  HALF_HOUR: {
    name: 'half an hour',
    title: 'for half an hour',
  },
  HOUR: {
    name: 'hour',
    title: 'per hour',
  },
  DAY: {
    name: 'day',
    title: 'per day',
  },
  WEEK: {
    name: 'week',
    title: 'per week',
  },
  MONTH: {
    name: 'month',
    title: 'per month',
  },
  PIECE: {
    name: 'thing',
    title: 'per item',
  },
  FT: {
    name: 'ft',
    title: 'per ft',
  },
  FT_2: {
    name: 'ft²',
    title: 'per ft²',
  },
  FT_3: {
    name: 'ft³',
    title: 'per ft³',
  },
  YD: {
    name: 'yd',
    title: 'per yd',
  },
  YD_2: {
    name: 'yd²',
    title: 'per yd²',
  },
  YD_3: {
    name: 'yd³',
    title: 'per yd³',
  },
  MI: {
    name: 'mi',
    title: 'per mile',
  },
} as const;
// MINUTE: {
//   name: 'minute',
//   title: 'per minute',
// },
// METRE: {
//   name: 'meter',
//   title: 'per meter',
// },
// SM_2: {
//   name: 'cm²',
//   title: 'per cm²',
// },
// M_2: {
//   name: 'm²',
//   title: 'per m²',
// },
// M_3: {
//   name: 'm³',
//   title: 'per m³',
// },
// KM: {
//   name: 'km',
//   title: 'per km',
// },
// ACR: {
//   name: 'weave',
//   title: 'per hundred',
// },
// GRAM: {
//   name: 'gram',
//   title: 'per gram',
// },
// KG: {
//   name: 'kg',
//   title: 'per kg',
// },
// TONNE: {
//   name: 'ton',
//   title: 'per ton',
// },
// R_M: {
//   name: 'lm',
//   title: 'per line m',
// },

import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  cardAnatomy.keys,
);

const variants = {
  button: definePartsStyle({
    container: {
      width: 'full',
      borderColor: 'border.medium',
      borderWidth: '1px',
      boxShadow: 'none',
      px: 'md',
      py: 'sm',
      bg: 'bg.light',
      _hover: {
        borderColor: 'primary.base',
        cursor: 'pointer',
      },
    },
  }),
  outline: definePartsStyle({
    container: {
      borderColor: 'border.medium',
      borderWidth: '1px',
      boxShadow: 'none',
      p: 'xl',
      bg: 'bg.light',
      borderRadius: 'xl',
    },
  }),
};

export default defineMultiStyleConfig({
  variants,
  baseStyle: { container: { p: 'md', overflow: 'hidden' } },
});

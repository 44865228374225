import { retryExchange } from '@urql/exchange-retry';
import { useEffect } from 'react';
import { cacheExchange, fetchExchange, ssrExchange, useMutation as useMutationUrql } from 'urql';
import { timeInSeconds } from '@/sharedLib';
import { getToken } from './auth';
import { initUrqlClient } from './init-urql-client';

export const checkRequestErrors = (cache, time) => {
  const errors = [];
  const cacheKeys = Object.keys(cache);

  cacheKeys.forEach((e) => {
    if (cache[e]?.error && cache[e]?.error?.graphQLErrors?.[0]?.extensions?.code !== 404) {
      errors.push(e);
    }
  });

  const urqlState = cache;
  let revalidate = time;

  if (errors.length) {
    revalidate = timeInSeconds.ONE_SEC;
    errors.forEach((e) => delete urqlState[e]);
  }

  return {
    revalidate,
    urqlState,
  };
};

export const retryExchangeOptions = {
  initialDelayMs: 1000,
  maxDelayMs: 15000,
  randomDelay: true,
  maxNumberAttempts: 2,
  retryIf: (error) => error?.graphQLErrors?.[0]?.extensions?.code !== 404,
};

export const getServerCLient = () => {
  const ssrCache = ssrExchange({ isClient: false });
  const client = initUrqlClient({
    exchanges: [cacheExchange, retryExchange(retryExchangeOptions), ssrCache, fetchExchange],
    fetchOptions: () => {
      const token = getToken();
      return {
        headers: { authorization: token ? token : '' },
      };
    },
  });
  return {
    client,
    ssrCache,
  };
};
export const useMutation = (mutation, actions) => {
  const { onCompleted, onError, onLoad } = actions;
  const [{ fetching, data, error }, action] = useMutationUrql(mutation);

  useEffect(() => {
    if (fetching) return onLoad?.();
    if (data && typeof data === 'object' && !fetching && onCompleted) {
      const dataKeys = Object.keys(data);
      const completedData = data?.[dataKeys?.[0]];
      if (completedData && completedData !== null) {
        onCompleted(data);
      }
    }
    if (error && !fetching && onError) {
      onError(error);
    }
  }, [fetching, data, error]);

  return [action, fetching, data, error];
};

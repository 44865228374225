import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const solidVariant = defineStyle({
  borderRadius: 'full',
  px: '2xs',
  py: '4xs',
  textTransform: 'none',
  fontSize: { base: 'sm', md: 'md' },
});

const badgeTheme = defineStyleConfig({
  variants: { solid: solidVariant },
});

export default badgeTheme;

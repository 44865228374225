import { TAccept } from './constants';

const googleMapsApiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;

if (!googleMapsApiKey) {
  throw new Error('NEXT_PUBLIC_GOOGLE_MAPS_API_KEY is not defined');
}

const googlePlacesApiKey =
  process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY || process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;

if (!googlePlacesApiKey) {
  throw new Error('NEXT_PUBLIC_GOOGLE_PLACES_API_KEY is not defined');
}

const countryKey = process.env.NEXT_PUBLIC_COUNTRY_KEY || 'USA';
const defaultLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE || 'en';

const isProd = !!process.env.NEXT_PUBLIC_IS_PROD;

const devBaseUrl =
  process.env.NEXT_PUBLIC_VERCEL_URL || process.env.VERCEL_URL || 'http://localhost:3000';

const baseUrl =
  isProd && process.env.NEXT_PUBLIC_BASE_URL ? process.env.NEXT_PUBLIC_BASE_URL : devBaseUrl;

const apiUrl = isProd
  ? process.env.NEXT_PUBLIC_API_URL
  : process.env.NEXT_PUBLIC_API_URL || 'https://devapi.localprof.com';

const revalidateSecret = process.env.REVALIDATE_SECRET || 'revalidateSecret123456';

if (!apiUrl) {
  throw new Error('NEXT_PUBLIC_API_URL is not defined');
}

const config = {
  baseUrl,
  acceptImageTypes: ['jpg', 'png'] satisfies TAccept[],
  maxImageSize: 10000000,
  isProd,
  googleMapsApiKey,
  apiUrl,
  countryKey,
  defaultLocale,
  googlePlacesApiKey,
  revalidateSecret,
} as const;

export default config;

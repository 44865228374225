import { makeOperation } from 'urql';
import { config } from '@/sharedLib';
import { fetcher } from '../fetcher';
import localStorage from '../localStorage';

const TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const PERMISSION = 'permissions';

const saveAuthState = ({ token, refreshToken }) => {
  if (typeof window === 'undefined') return;
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

const clearAuthState = () => {
  if (typeof window === 'undefined') return;
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(PERMISSION);
};

const getToken = () => {
  if (typeof window === 'undefined') return null;
  return localStorage.getItem(TOKEN_KEY);
};

const getRefreshToken = () => {
  if (typeof window === 'undefined') return null;
  return localStorage.getItem(REFRESH_TOKEN_KEY);
};

const getRequest = (token) =>
  fetcher.post(`${config.apiUrl}/refreshtoken`, {
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    body: { token },
  });

const getAuth = async ({ authState }) => {
  let refreshToken = authState?.refreshToken;
  if (!refreshToken) {
    refreshToken = getRefreshToken();
  }

  if (!refreshToken) {
    return null;
  }
  let result;
  try {
    result = await getRequest(refreshToken);
  } catch (err) {
    if (err?.response?.data === 'Expired token') {
      clearAuthState();
    }
    return null;
  }

  if (result.data?.refreshToken) {
    saveAuthState({
      token: result.data?.accessToken,
      refreshToken: result.data?.refreshToken,
    });
    return result.data;
  }

  clearAuthState();
  window.location.reload();

  return null;
};

const addAuthToOperation = ({ authState, operation }) => {
  const token = authState?.token || getToken();
  if (!token) {
    return operation;
  }

  const fetchOptions =
    typeof operation.context.fetchOptions === 'function'
      ? operation.context.fetchOptions()
      : operation.context.fetchOptions || {};

  return makeOperation(operation?.kind, operation, {
    ...operation.context,
    fetchOptions: {
      ...fetchOptions,
      headers: {
        ...fetchOptions.headers,
        authorization: token,
      },
    },
  });
};

const didAuthError = ({ error }) => {
  return error.graphQLErrors.some((e) => e.extensions?.code === 'EXPIRED');
};

export {
  getAuth,
  didAuthError,
  saveAuthState,
  getToken,
  addAuthToOperation,
  getRefreshToken,
  clearAuthState,
};

import { BoxProps, ButtonProps, ContainerProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { configModel, isMobileSelector } from '@/entities/Config';
import ModalDesktop from './ModalDesktop';
import ModalMobile from './ModalMobile';

export const modalPadding: Pick<BoxProps, 'px' | 'py'> = {
  px: { base: 'md', md: 'lg' },
  py: { base: 'md', md: 'lg' },
};

export interface ModalProps {
  children: ReactNode;
  header?: string;
  isOpen: boolean;
  negativeButtonHandler?: ButtonProps['onClick'];
  negativeButtonText?: string;
  negativeButtonStyles?: Omit<ButtonProps, 'onClick'>;
  positiveButtonStyles?: Omit<ButtonProps, 'onClick'>;
  onClose: () => void;
  positiveButtonHandler?: ButtonProps['onClick'];
  positiveButtonText?: string;
  showCloseButton?: boolean;
  width?: BoxProps['width'];
  withPadding?: boolean;
  containerProps?: ContainerProps;
}

const Modal: FC<ModalProps> = (props) => {
  const isMobile = configModel.useStore(isMobileSelector);

  return isMobile ? <ModalMobile {...props} /> : <ModalDesktop {...props} />;
};

export default Modal;

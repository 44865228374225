import { imageSizes } from '../constants';
import { IImageFile } from '../types';

export const extractImage = (image: IImageFile, type: string) => {
  if (type === imageSizes.ORIGINAL) return image.url;
  const src = image?.thmb?.find((el) => el.type === type)?.url;
  return src || image?.url;
};

export const prepareImageForSubmit = (image: IImageFile) => ({
  id: image?.id || undefined,
  url: image?.url,
  mimeType: image?.mimeType || undefined,
  thmb: image?.thmb || undefined,
  fileName: image?.fileName,
  rawFileSize: image?.rawFileSize || undefined,
  rawFile: image?.rawFile || undefined,
  aspectRatio: image?.aspectRatio || undefined,
});

import { FetchHelperResponse } from './types';

class ResponseError<T> extends Error {
  response: FetchHelperResponse<T>;

  constructor({ data, headers, status, statusText }: FetchHelperResponse<T>) {
    super(statusText);
    this.response = {
      data,
      headers,
      status,
      statusText,
    };
  }
}

export default ResponseError;

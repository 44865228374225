import { useQuery } from 'urql';
import { ILandingConfig, configModel, coreConfigSelector } from '@/entities/Config';
import { IImageFile, ReviewSourceTypeEnum, extractImage, imageSizes } from '@/sharedLib';
import { LANDING_EDIT_CONFIG, LANDING_USER_DATA, USER_FEEDBACKS, USER_PRICES } from '../gql';
import servicesMapper from '../lib/servicesMapper';

const useProfile = () => {
  const { subdomain } = configModel.useGetStoreData(coreConfigSelector);
  const [{ data, fetching: loading }] = useQuery({
    query: LANDING_USER_DATA,
    variables: { subdomain },
    pause: !subdomain,
  });

  return { profileData: data?.landingUserData, loading };
};

const useFeedbacks = (userId: string) => {
  const [{ data: userFeedbacks }] = useQuery({
    query: USER_FEEDBACKS,
    variables: {
      input: {
        id: userId,
        filter: {
          criteria: {
            id: 'type',
            values: 'EXECUTOR',
          },
        },
      },
    },
    pause: !userId,
  });

  return userFeedbacks?.feedbacks?.results
    ? userFeedbacks?.feedbacks?.results?.map((review: any) => ({
        id: review.id,
        rating: review?.rating,
        text: review?.text,
        sourceType:
          review?.sourceType === ReviewSourceTypeEnum.GOOGLE
            ? ReviewSourceTypeEnum.GOOGLE
            : ReviewSourceTypeEnum.LOCAL_PROF,
        author: {
          name: review?.author?.name
            ? review.author.name
            : review.from?.name + ' ' + review.from?.lastName,
          photo: review?.author?.photo
            ? review.author.photo
            : extractImage(review.from?.image as IImageFile, imageSizes.S),
        },
        createdAt: parseInt(review?.createdAt, 10),
        jobType: review.jobTypeName,
        images: review?.images?.length ? review.images : undefined,
      }))
    : [];
};

const usePrices = (userId: string) => {
  const [{ data: userPrices, fetching: loading }] = useQuery({
    query: USER_PRICES,
    variables: { userId },
    pause: !userId,
  });
  return {
    data: servicesMapper(userPrices?.jobTypesWithUserPrices?.jobTypesWithUserPrices || []),
    loading,
  };
};

const useEditConfig = ({ pause }: { pause?: boolean } = {}) => {
  const [{ data, fetching: isLoading }] = useQuery({
    query: LANDING_EDIT_CONFIG,
    pause,
  });

  const editConfig: ILandingConfig | undefined =
    data?.landingEditConfig && JSON.parse(data?.landingEditConfig?.config);

  return { editConfig, isLoading };
};

const model = { useProfile, useFeedbacks, usePrices, useEditConfig };

export default model;

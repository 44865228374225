import {
  IInfoConfig,
  IPhotoGallery,
  TBlockData,
  TBlockList,
  TContactData,
  TEditBlockName,
} from '@/sharedLib';
import { ILandingConfig } from '../types/landingConfig';
import { TFullConfigState } from './configStore';

const getConfig = (s: TFullConfigState): ILandingConfig =>
  s.coreConfig.isEdit ? s.editConfig || {} : s.config || {};

export const mainBlockSelector = (s: TFullConfigState): TBlockData['main'] => {
  const config = getConfig(s);

  return config.mainBlock || {};
};

const getContactData = (config?: ILandingConfig): TContactData => ({
  address: config?.address,
  email: config?.email,
  placeId: config?.placeId,
  placeName: config?.placeName,
  workingHours: config?.workingHours,

  phone: config?.phone,
  xLink: config?.xLink,
  fbLink: config?.fbLink,
  instagramLink: config?.instagramLink,
  telegramLink: config?.telegramLink,
});

export const headerSelector = (s: TFullConfigState): TBlockData['header'] => {
  const config = getConfig(s);

  return {
    ...(config.header || {}),
    logo: config.logo,
    contactData: getContactData(config),
  };
};

export const footerSelector = (s: TFullConfigState): TBlockData['footer'] => {
  const config = getConfig(s);

  return {
    ...(config.footer || {}),
    logo: config.logo,
    companyName: config.companyName,
    menuLinks: s.coreConfig?.menuLinks,
    contactData: getContactData(config),
  };
};

export const themeSelector = (s: TFullConfigState) => {
  const config = getConfig(s);

  return config.theme;
};

export const templateSelector = (s: TFullConfigState) => {
  const config = getConfig(s);

  return config.templates || {};
};

export const portfolioSelector = (s: TFullConfigState): TBlockData['portfolio'] => {
  const config = getConfig(s);

  return {
    heading: config.portfolio?.heading,
    portfolio: config.portfolio?.portfolio || [],
  };
};

export const getPhotoGallerySelector =
  (index: number | null) =>
  (s: TFullConfigState): IPhotoGallery => {
    const config = getConfig(s);

    const photoGallery = config.photoGallery || [];

    return index !== null ? photoGallery[index] || {} : {};
  };

export const logoSelector = (s: TFullConfigState) => {
  const config = getConfig(s);

  return config.logo;
};

export const blocksListSelector = (s: TFullConfigState): TBlockList => {
  const config = getConfig(s);

  return config.blocksList || [];
};

export const reviewsSelector = (s: TFullConfigState): TBlockData['reviews'] => {
  const config = getConfig(s);

  return {
    ...(s.reviews || {}),
    yelpId: config.yelpId,
  };
};

export const personalDataSelector = (s: TFullConfigState) => {
  const config = getConfig(s);

  return {
    address: config?.address,
    address_url: config?.address_url,
    email: config?.email,
    phone: config?.phone,
    placeName: config?.placeName,
    workingHours: config?.workingHours,
    fbLink: config?.fbLink,
    xLink: config?.xLink,
    instagramLink: config?.instagramLink,
    telegramLink: config?.telegramLink,
    yelpId: config?.yelpId,
  };
};

export const menuLinksSelector = (s: TFullConfigState) => s?.coreConfig?.menuLinks;
export const editModalOpenSelector = (s: TFullConfigState): TEditBlockName | null =>
  typeof s?.coreConfig?.editModalOpen === 'string' ? s.coreConfig.editModalOpen : null;

export const companyNameSelector = (s: TFullConfigState) => {
  const config = getConfig(s);

  return config.companyName;
};

export const servicesSelector = (s: TFullConfigState): TBlockData['services'] => {
  const config = getConfig(s);

  return {
    services: config.services?.services,
    heading: config.services?.heading,
  };
};

export const getInfoSelector =
  (index: number | null) =>
  (s: TFullConfigState): IInfoConfig => {
    const config = getConfig(s);

    const infoBlocks = config.info || [];

    return index !== null ? infoBlocks[index] || {} : {};
  };

export const ourWorkersSelector = (s: TFullConfigState): TBlockData['workers'] => {
  const config = getConfig(s);

  return config.ourWorkers || {};
};

export const mapDataSelector = (s: TFullConfigState): TBlockData['map'] => ({
  address: s.config?.address,
  placeId: s.config?.placeId,
});

export const contactsSelector = (s: TFullConfigState): TBlockData['contacts'] => {
  const config = getConfig(s);

  return {
    address: config.address,
    placeId: config.placeId,
    address_url: config.address_url,
    email: config.email,
    phone: config.phone,
    workingHours: config.workingHours,
  };
};

export const settingsSelector = (s: TFullConfigState): TBlockData['generalSettings'] => {
  const config = getConfig(s);

  return {
    companyName: config.companyName,
    theme: config.theme,
  };
};

export const mapSelector = (s: TFullConfigState) => {
  const config = getConfig(s);

  return {
    address: config.address,
    placeId: config.placeId,
    phone: config.phone,
    placeName: config.placeName,
  };
};

export const metaDecoratorSelector = (s: TFullConfigState) => ({
  logo: s.config?.logo,
  colors: s.config?.theme?.colors,
  bgImage: s.config?.mainBlock?.bgImage,
  companyName: s.config?.companyName,
});

export const isMobileSelector = (s: TFullConfigState) => !!s.coreConfig?.isMobile;

export const coreConfigSelector = (s: TFullConfigState) => s.coreConfig;

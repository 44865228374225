import Badge from './badge';
import Button from './button';
import Card from './card';
import Checkbox from './checkbox';
import CloseButton from './closeButton';
import Heading from './heading';
import Input from './input';
import Link from './link';
import List from './list';
import Radio from './radio';
import Select from './select';
import Spinner from './spinner';
import Switch from './switch';
import Text from './text';

const components = {
  Badge,
  Button,
  Card,
  Checkbox,
  CloseButton,
  Heading,
  Link,
  List,
  Radio,
  Switch,
  Text,
  Input,
  Select,
  Spinner,
};

export default components;

import { gql } from '@urql/core';

const UPDATE_LANDING_EDIT_CONFIG = gql`
  mutation updateLandingEditConfig($input: UpdateLandingConfigInput!) {
    updateLandingEditConfig(input: $input) {
      config
    }
  }
`;

const PUBLISH_LANDING_CONFIG = gql`
  mutation publishLandingConfig {
    publishLandingConfig {
      subdomain
      config
      tenantId
    }
  }
`;

export { UPDATE_LANDING_EDIT_CONFIG, PUBLISH_LANDING_CONFIG };

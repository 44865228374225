import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  radioAnatomy.keys,
);

const baseStyle = definePartsStyle({
  label: {
    width: 'full',
  },
});

export default defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    colorScheme: 'primary',
  },
});

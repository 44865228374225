import { useEffect } from 'react';
import { configModel } from '@/entities/Config';
import { debounce } from '@/sharedLib';

const SyncIsMobile = () => {
  const { updateIsMobile } = configModel.useStoreMethods();

  useEffect(() => {
    const checkIsMobile = () => {
      updateIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', debounce(checkIsMobile));
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return null;
};

export default SyncIsMobile;

import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const blurVariant = defineStyle({
  _hover: {
    bg: 'bg.pure',
    color: `secondary.dark`,
  },
  backdropFilter: 'blur(15px)',
  bg: 'rgba(255, 255, 255, 0.7)',
  color: `secondary.base`,
});

const buttonTheme = defineStyleConfig({
  defaultProps: {
    colorScheme: 'primary',
    variant: 'blur',
  },
  variants: { blur: blurVariant },
});

export default buttonTheme;

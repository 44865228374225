import { listAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const sizes = {
  xl: definePartsStyle({
    container: defineStyle({
      fontSize: 'xl',
    }),
    icon: defineStyle({
      boxSize: 6,
    }),
  }),
  lg: definePartsStyle({
    container: defineStyle({
      fontSize: 'lg',
    }),
    icon: defineStyle({
      boxSize: 5,
    }),
  }),
  md: definePartsStyle({
    container: defineStyle({
      fontSize: 'md',
    }),
    icon: defineStyle({
      boxSize: 4,
    }),
  }),
};

export default defineMultiStyleConfig({ sizes });

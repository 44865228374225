import { CombinedError, useQuery } from 'urql';
import { saveAuthState, useMutation } from '@/sharedAPI';
import { UNKNOWN_ERROR_TEXT, parseGqlError } from '@/sharedLib';
import { LOGIN_USER, ME } from '../gql';

const useGetMe = ({ pause }: { pause?: boolean } = {}) => {
  const [me, reexecuteQuery] = useQuery({
    query: ME,
    pause,
  });

  const refetch = () => reexecuteQuery({ requestPolicy: 'network-only' });

  return { me: me?.data?.me, refetch, isLoading: me?.fetching };
};

type TUseLoginProps = {
  onSuccess?: () => void;
  onError?: (error: { message: string }) => void;
};

const useLogin = ({ onSuccess, onError }: TUseLoginProps = {}) => {
  const { refetch } = useGetMe();

  const [loginAction, loading] = useMutation(LOGIN_USER, {
    onCompleted: (data: any) => {
      if (data?.login?.accessToken && data?.login?.refreshToken) {
        saveAuthState({
          token: data?.login?.accessToken,
          refreshToken: data?.login?.refreshToken,
        });
        refetch();
        onSuccess?.();
      }
    },
    onError: (error: CombinedError | undefined) => {
      const parseError = parseGqlError(error?.graphQLErrors);
      // TODO: надо доработать авторизацию, что бы пользователь у которого не создана страница Pages не мог авторизоваться
      onError?.({ message: parseError.code === 404 ? 'User not found' : UNKNOWN_ERROR_TEXT });
    },
  });

  return { loginAction, isLoading: loading };
};

const model = {
  useGetMe,
  useLogin,
};

export default model;

const uploadFileWithProgress = (
  url: string,
  formData: FormData,
  onProgress?: (progress: number) => void,
  options?: any,
) =>
  new Promise<Response>((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    if (onProgress) {
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          onProgress(progress);
        }
      };
    }

    xhr.upload.onerror = (error) => {
      reject(error);
    };

    xhr.open('POST', url, true);

    if (options.headers) {
      for (const [key, value] of Object.entries(options.headers)) {
        xhr.setRequestHeader(key, value as string);
      }
    }

    xhr.send(formData);

    xhr.onload = () => {
      const response = new Response(xhr.response, {
        status: xhr.status,
        statusText: xhr.statusText,
      });
      resolve(response);
    };
  });

export default uploadFileWithProgress;

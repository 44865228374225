import { extendTheme } from '@chakra-ui/react';
import { theme } from '@/app/theme';
import { themeRadiuses } from '../constants';
import { TUserTheme } from '../types/UserTheme';
import setColors from './setColors';

const setTheme = (userTheme?: TUserTheme) => {
  const newTheme = { ...theme };

  if (userTheme) {
    newTheme.colors = userTheme.colors
      ? setColors(userTheme.colors, newTheme.colors)
      : newTheme.colors;

    newTheme.radii = userTheme.radius ? themeRadiuses[userTheme.radius] : newTheme.radii;
  }

  return extendTheme(newTheme);
};

export default setTheme;

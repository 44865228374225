import { Box, Toast, useToast as useNotificationToast } from '@chakra-ui/react';
import { ReactNode } from 'react';

const useToast = () => {
  const toast = useNotificationToast();
  const showErrorToast = (title: ReactNode, description?: ReactNode) => {
    toast({
      title,
      description,
      status: 'error',
      duration: 4000,
      isClosable: true,
      position: 'bottom',
      containerStyle: {
        w: 'full',
        px: 'xs',
      },
      render: (props) => (
        <Box data-testid='error-toast'>
          <Toast
            {...props}
            width='full'
          />
        </Box>
      ),
    });
  };

  const showSuccessToast = (title: ReactNode, description?: ReactNode) => {
    toast({
      title,
      description,
      status: 'success',
      duration: 4000,
      isClosable: true,
      position: 'bottom',
      containerStyle: {
        w: 'full',
        px: 'xs',
      },
      render: (props) => (
        <Box data-testid='success-toast'>
          <Toast
            {...props}
            width='full'
          />
        </Box>
      ),
    });
  };

  const showInfoToast = (title: ReactNode, description?: ReactNode) => {
    toast({
      title,
      description,
      status: 'info',
      duration: 4000,
      isClosable: true,
      position: 'bottom',
      containerStyle: {
        w: 'full',
        px: 'xs',
      },
      render: (props) => (
        <Box data-testid='info-toast'>
          <Toast
            {...props}
            width='full'
          />
        </Box>
      ),
    });
  };

  return {
    showErrorToast,
    showSuccessToast,
    showInfoToast,
  };
};

export default useToast;

import { IPriceInfo, TPrice, TServiceCard, units } from '@/sharedLib';

const formatPrice = (nestedPrice: IPriceInfo) => {
  if (!nestedPrice.priceTo && !nestedPrice.priceFrom && !nestedPrice.price) return null;

  if (nestedPrice.price) return '$' + nestedPrice.price;
  if (nestedPrice.priceFrom && nestedPrice.priceTo)
    return '$' + nestedPrice.priceFrom + '-' + nestedPrice.priceTo;
  if (nestedPrice.priceFrom && !nestedPrice.priceTo) return `from $${nestedPrice.priceFrom}`;
};

const createPriceList = (prices: IPriceInfo[]) => {
  return (
    prices
      ?.map((nestedPrice) => {
        const formattedPrice = formatPrice(nestedPrice);
        if (!formattedPrice) return null;
        return {
          price: formattedPrice,
          unit: units[nestedPrice.unit as keyof typeof units].title,
        };
      })
      .filter((price) => price !== null) || []
  );
};

const createServiceCard = (price: TPrice, isHighlight: boolean) => {
  const pricesList = createPriceList(price?.prices as IPriceInfo[]);
  return {
    header: price.name,
    description: price?.prices?.[0]?.description || `Description of ${price.name} here`,
    price: pricesList,
    highlight: isHighlight,
  };
};

const mapPricesToServices = (prices: TPrice[]) => {
  const services: TServiceCard[] = [];
  // Первый уровень вложенности - это RV Services - его в карточках не показываем
  // Показываем 2 и 3 уровень вложенности
  prices.forEach((first) => {
    first.items?.forEach((second) => {
      const serviceCard = createServiceCard(second, true);
      services.push(serviceCard);
      second?.items?.forEach((third) => {
        const nestedServiceCard = createServiceCard(third, false);
        services.push(nestedServiceCard as TServiceCard);
      });
    });
  });
  return services;
};

const servicesMapper = (prices: TPrice[]): TServiceCard[] | null => {
  const services = mapPricesToServices(prices);
  return services.length > 0 ? services : null;
};

export default servicesMapper;

import { gql } from '@urql/core';

const fileFragment = gql`
  fragment FileFrag on File {
    id
    fileName
    url
    type
    mimeType
    tag
    aspectRatio
    thmb {
      id
      url
      type
      mimeType
    }
  }
`;

const feedbacksFragments = gql`
  fragment FeedbackFrag on Feedback {
    text
    images {
      ...FileFrag
    }
    rating
    jobTypeName
    createdAt
    from {
      name
      lastName
      image {
        ...FileFrag
      }
    }
    author {
      name
      photo
    }
    sourceType
  }
  ${fileFragment}
`;

const LANDING_EDIT_CONFIG = gql`
  query landingEditConfig {
    landingEditConfig {
      config
      tenantId
    }
  }
`;

const LANDING_PUBLIC_CONFIG = gql`
  query landingPublicConfig($subdomain: String) {
    landingPublicConfig(subdomain: $subdomain) {
      subdomain
      config
      tenantId
    }
  }
`;

const LANDING_USER_DATA = gql`
  query landingUserData($subdomain: String) {
    landingUserData(subdomain: $subdomain) {
      phone
      profile {
        id
        name
        image {
          ...FileFrag
        }
        portfolio {
          id
          jobTypeId
          title
          description
          price
          images {
            ...FileFrag
          }
        }
        jobTypes {
          id
          name
          path
          items {
            id
            name
            path
            items {
              id
              name
              path
              items {
                id
                name
                path
              }
            }
          }
        }
        googlePlace {
          id
          name
        }
        description
      }
    }
  }
  ${fileFragment}
`;

const USER_PRICES = gql`
  query jobTypesWithUserPrices($userId: String) {
    jobTypesWithUserPrices(userId: $userId) {
      jobTypesWithUserPrices {
        name
        items {
          name
          prices {
            price
            priceTo
            priceFrom
            unit
            description
          }
          items {
            name
            prices {
              price
              priceTo
              priceFrom
              unit
              description
            }
          }
        }
      }
    }
  }
`;

const USER_FEEDBACKS = gql`
  query feedbacks($input: FeedbacksInput) {
    feedbacks(input: $input) {
      results {
        ...FeedbackFrag
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
      }
    }
  }
  ${feedbacksFragments}
`;

export {
  USER_PRICES,
  USER_FEEDBACKS,
  LANDING_EDIT_CONFIG,
  LANDING_USER_DATA,
  LANDING_PUBLIC_CONFIG,
};

import { TBlockKey } from '../types';

type TMenuLink = {
  name: TBlockKey;
  link: TBlockKey;
};

export const menuLinks: TMenuLink[] = [
  { name: 'reviews', link: 'reviews' },
  { name: 'portfolio', link: 'portfolio' },
  { name: 'services', link: 'services' },
  { name: 'info', link: 'info' },
  { name: 'contacts', link: 'contacts' },
];

import { ChakraProvider } from '@chakra-ui/react';
import { NextPage } from 'next';
import type { AppContext, AppProps } from 'next/app';
import { Montserrat } from 'next/font/google';
import TagManager from 'react-gtm-module';
import { ConfirmPopup } from '@/app/ConfirmPopup';
import { SyncIsMobile } from '@/app/SyncIsMobile';
import '@/app/styles/scroll.css';
import { ConfigStoreProvider } from '@/entities/Config';
import { FullWindowDropzone } from '@/entities/Dropzone';
import { withUrqlClient } from '@/sharedAPI';
import { setTheme } from '@/sharedLib';

interface MyAppProps extends AppProps {
  isMobileSSR: boolean;
}

const gtmId = process.env.gtmId;

export const montserrat = Montserrat({
  subsets: ['latin'],
  variable: '--font-montserrat',
});

if (gtmId) {
  const tagManagerArgs = {
    gtmId,
  };

  if (typeof window !== 'undefined') {
    setTimeout(() => TagManager.initialize(tagManagerArgs), 3000);
  }
}

const App: NextPage<MyAppProps> = ({ Component, pageProps }) => {
  const userTheme = setTheme(pageProps?.config?.theme);

  return (
    <ConfigStoreProvider
      config={pageProps?.config}
      isMobile={pageProps.isMobileSSR}
      menuLinks={pageProps?.menuLinks}
      profileData={pageProps?.profileData}
      reviews={pageProps?.reviews}
      userSubdomain={pageProps?.subdomain}
    >
      <div className={montserrat.className}>
        <ChakraProvider
          theme={userTheme}
          toastOptions={{
            defaultOptions: {
              duration: 3000,
              isClosable: true,
              position: 'top',
            },
          }}
        >
          <FullWindowDropzone>
            <SyncIsMobile />
            <ConfirmPopup />
            <Component {...pageProps} />
          </FullWindowDropzone>
        </ChakraProvider>
      </div>
    </ConfigStoreProvider>
  );
};

const WrappedComponent = withUrqlClient()(App);

(WrappedComponent as any).getInitialProps = async ({ ctx }: AppContext) => {
  const userAgent = ctx.req ? ctx.req.headers['user-agent'] : navigator.userAgent;
  const isMobile = /mobile/i.test(userAgent || '');

  return {
    pageProps: {
      isMobileSSR: isMobile,
    },
  };
};

export default WrappedComponent;

import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const labelVariant = defineStyle((props) => {
  const { colorScheme } = props;

  return {
    fontSize: 'sm',
    noOfLines: 1,
  };
});

const metaVariant = defineStyle((props) => {
  const { colorScheme } = props;

  return {
    fontSize: 'xs',
    noOfLines: 1,
  };
});

const secondaryVariant = defineStyle((props) => {
  const { colorScheme } = props;

  return {
    fontSize: 'sm',
  };
});

const largeVariant = defineStyle({
  color: 'typography.base',
  fontSize: { base: 'xl', md: '2xl' },
  fontWeight: 'semibold',
});

const variants = {
  label: labelVariant,
  secondary: secondaryVariant,
  large: largeVariant,
  meta: metaVariant,
};

export default defineStyleConfig({ variants });

import type { Styles } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

const isWindows = typeof window !== 'undefined' && window.navigator.userAgent.includes('Win');

const windowScrollStyles: Styles['global'] = isWindows
  ? {
      '::-webkit-scrollbar': {
        width: '4px',
        height: '4px',
        background: '#CBD5E0',
        borderRadius: '8px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#4A5568',
        borderRadius: '8px',
      },
    }
  : {};

const styles: Styles = {
  global: (props) => ({
    body: {
      color: mode('typography.dark', 'whiteAlpha.900')(props),
      bg: mode('bg.light', 'bg.900')(props),
      lineHeight: 'base',
      fontWeight: 'medium',
    },
    '*::placeholder': {
      color: mode('typography.600', 'whiteAlpha.400')(props),
    },
    ...windowScrollStyles,
  }),
};

export default styles;

import { Center, Spinner } from '@chakra-ui/react';

const Loader = () => (
  <Center
    bg='transparent.dark'
    h='100vh'
    position='fixed'
    w='100%'
    zIndex='10'
  >
    <Spinner
      emptyColor='primary.light'
      speed='0.65s'
      thickness='6px'
    />
  </Center>
);

export default Loader;

import chroma from 'chroma-js';
import { scaleLinear } from 'd3-scale';
import { TChakraColors, TUserColors } from '../types';

const scales = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];

const startDomain = 0.1;
const endDomain = 0.9;

const createPalette = (hex: string, selectedScale: number) => {
  const luminance = chroma(hex).luminance();
  const selectedIndex = scales.indexOf(selectedScale);
  const logScaleBefore = scaleLinear()
    .domain([scales[0], scales[selectedIndex + 1]])
    .range([luminance, endDomain]);

  const previousScales = [...Array(selectedIndex + 1)]
    .map((_, index) => {
      return logScaleBefore(scales[index]);
    })
    .reverse();

  const afterCount = scales.length - (selectedIndex + 1);
  const logScaleAfter = scaleLinear()
    .domain([scales[selectedIndex], scales[scales.length - 1]])
    .range([luminance, startDomain]);

  const nextScales = [...Array(afterCount)].map((_, index) => {
    return logScaleAfter(scales[selectedIndex + 1 + index]);
  });

  const palette = [...previousScales, ...nextScales].map((lum) => {
    return chroma(hex).luminance(lum).hex();
  });

  return {
    50: palette[0],
    100: palette[1],
    200: palette[2],
    300: palette[3],
    400: palette[4],
    500: palette[5],
    600: palette[6],
    700: palette[7],
    800: palette[8],
    900: palette[9],
    light: palette[0],
    medium: palette[3],
    base: palette[5],
    dark: palette[8],
  };
};

const setColors = (colors?: TUserColors, themeColors?: TChakraColors) => {
  const newColors = { ...themeColors };

  if (colors?.primary) {
    newColors.primary = createPalette(colors?.primary, 500);
  }

  if (colors?.secondary) {
    newColors.secondary = createPalette(colors?.secondary, 500);
  }

  return newColors;
};

export default setColors;

import dynamic from 'next/dynamic';
import { FC, ReactNode } from 'react';
import { configModel, isMobileSelector } from '@/entities/Config';

const DropzoneProvider = dynamic(() => import('../model').then((mod) => mod.DropzoneProvider));
const FullDropzone = dynamic(() => import('./FullDropzone'));

const FullWindowDropzone: FC<{ children: ReactNode }> = ({ children }) => {
  const isMobile = configModel.useStore(isMobileSelector);

  if (isMobile) {
    return <>{children}</>;
  }

  return (
    <DropzoneProvider>
      <FullDropzone>{children}</FullDropzone>
    </DropzoneProvider>
  );
};

export default FullWindowDropzone;

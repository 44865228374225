import { gql } from '@urql/core';

const ME = gql`
  query me {
    me {
      id
      name
      permission
      status
    }
  }
`;

const LOGIN_USER = gql`
  mutation login($input: loginInput!) {
    login(input: $input) {
      user {
        name
        id
        permission
      }
      accessToken
      refreshToken
    }
  }
`;

export { LOGIN_USER, ME };

import { stringify } from 'querystring';
import { HttpStatusEnum } from '@/sharedLib';
import ResponseError from './error';
import {
  FetchHelperResponse,
  FetcherConfig,
  Init,
  Options,
  RequestInterceptor,
  ResponseInterceptor,
} from './types';
import uploadFileWithProgress from './uploadFileWithProgress';

class InterceptorManager<T> {
  private interceptors: T[] = [];

  add(interceptor: T) {
    this.interceptors.push(interceptor);
    return () => {
      const index = this.interceptors.indexOf(interceptor);
      if (index !== -1) {
        this.interceptors.splice(index, 1);
      }
    };
  }

  getInterceptors() {
    return this.interceptors;
  }
}
class BaseFetcher {
  baseUrl: string;
  defaultHeaders: HeadersInit;
  interceptors: {
    request: InterceptorManager<RequestInterceptor>;
    response: InterceptorManager<ResponseInterceptor>;
  };

  constructor({ baseUrl = '', headers = {} }: FetcherConfig = {}) {
    this.baseUrl = baseUrl;
    this.defaultHeaders = headers;
    this.interceptors = {
      request: new InterceptorManager<RequestInterceptor>(),
      response: new InterceptorManager<ResponseInterceptor>(),
    };
  }

  protected async internalFetchHelper<T = any>(
    url: string,
    init?: Init,
    baseUrl?: string,
  ): Promise<FetchHelperResponse<T>> {
    let fullUrl = baseUrl ? baseUrl + url : url;
    const combinedHeaders: HeadersInit = { ...this.defaultHeaders, ...init?.headers };
    let updatedInit: Init | undefined = { ...init, headers: combinedHeaders };

    // Apply request interceptors
    for (const interceptor of this.interceptors.request.getInterceptors()) {
      [fullUrl, updatedInit] = await interceptor(fullUrl, updatedInit);
    }

    const body =
      updatedInit?.body instanceof FormData ? updatedInit.body : JSON.stringify(updatedInit?.body);
    const { params, onUploadProgress, ...options } = updatedInit || ({} as Init);
    const urlWithQueryParams = params ? fullUrl + '?' + stringify(params) : fullUrl;

    let response: Response;
    if (body instanceof FormData && options.method === 'POST') {
      response = await uploadFileWithProgress(urlWithQueryParams, body, onUploadProgress, options);
    } else {
      response = await fetch(urlWithQueryParams, { ...options, body });
    }
    const { ok, status, statusText, headers } = response;

    if (ok) {
      // Apply response success interceptors
      for (const interceptor of this.interceptors.response.getInterceptors()) {
        response = await interceptor.resolve(response);
      }
      let data;
      try {
        data = response.status === HttpStatusEnum.NO_CONTENT ? undefined : await response.json();
      } catch (e) {
        // ignore
      }

      return {
        data,
        status,
        statusText,
        headers,
      };
    } else {
      const textBody = body ? await response.text() : null;

      try {
        const jsonBody = textBody ? JSON.parse(textBody) : null;
        const error = new ResponseError({
          data: jsonBody || textBody,
          status,
          statusText,
          headers,
        });

        throw error;
      } catch (e) {
        let handledError = e as ResponseError<T>;

        if (!(e instanceof ResponseError)) {
          handledError = new ResponseError<T>({
            data: textBody as T,
            status,
            statusText,
            headers,
          });
        }

        // Apply response error interceptors
        for (const interceptor of this.interceptors.response.getInterceptors()) {
          try {
            const res = await interceptor.reject<T>(
              handledError,
              this.internalFetchHelper.bind(this),
              url,
              updatedInit,
            );

            return Promise.resolve(res);
          } catch (e) {
            throw e;
          }
        }

        throw handledError;
      }
    }
  }

  private setMethod<T = any>(method: string, url: string, options: Options = {} as Options) {
    return this.internalFetchHelper<T>(url, { ...options, method }, this.baseUrl);
  }

  public get<T = any>(url: string, options?: Options) {
    return this.setMethod<T>('GET', url, options);
  }

  public put<T = any>(url: string, options?: Options) {
    return this.setMethod<T>('PUT', url, options);
  }

  public post<T = any>(url: string, options?: Options) {
    return this.setMethod<T>('POST', url, options);
  }

  public del<T = any>(url: string, options?: Options) {
    return this.setMethod<T>('DELETE', url, options);
  }

  public patch<T = any>(url: string, options?: Options) {
    return this.setMethod<T>('PATCH', url, options);
  }
}

class Fetcher extends BaseFetcher {
  constructor() {
    super();
  }

  call(url: string, init?: Init) {
    return this.internalFetchHelper(url, init);
  }

  public create(config: FetcherConfig) {
    return new BaseFetcher(config);
  }

  public get<JSON = any>(url: string, options?: Options) {
    return super.get<JSON>(url, options);
  }

  public put<JSON = any>(url: string, options?: Options) {
    return super.put<JSON>(url, options);
  }

  public post<JSON = any>(url: string, options?: Options) {
    return super.post<JSON>(url, options);
  }

  public del<JSON = any>(url: string, options?: Options) {
    return super.del<JSON>(url, options);
  }

  public patch<JSON = any>(url: string, options?: Options) {
    return super.patch<JSON>(url, options);
  }
}

interface Fetcher {
  (url: string, init?: Init): Promise<FetchHelperResponse>;
}

const fetcher = new Fetcher();

export default fetcher;

import { ComponentDefaultProps, defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  color: 'primary.base',
});

const defaultProps = {
  size: 'xl',
} satisfies ComponentDefaultProps;

const spinnerTheme = defineStyleConfig({
  baseStyle,
  defaultProps,
});

export default spinnerTheme;

import { extendTheme } from '@chakra-ui/react';
import breakpoints from './breakpoints';
import colors from './colors';
import components from './components';
import borderRadius from './radii';
import shadows from './shadows';
import space from './space';
import styles from './styles';

const theme = extendTheme({
  breakpoints,
  colors,
  components,
  space,
  styles,
  shadows,
  borderRadius,
});

export default theme;

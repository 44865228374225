interface IStorage {
  accessToken?: string;
  refreshToken?: string;
  permissions?: string;
}
const storage: IStorage = {};

const setItem = (key: string, value: string) => {
  try {
    storage[key as keyof typeof storage] = value;
    window.localStorage.setItem(key, value);
  } catch (err) {
    return storage[key as keyof typeof storage];
  }
};

const removeItem = (key: string) => {
  try {
    storage[key as keyof typeof storage] = undefined;
    window.localStorage.removeItem(key);
  } catch (err) {
    return storage[key as keyof typeof storage];
  }
};

const getItem = (key: string) => {
  try {
    return window.localStorage.getItem(key);
  } catch (err) {
    return storage[key as keyof typeof storage];
  }
};

export default { setItem, removeItem, getItem };

import { createStore } from 'zustand';
import {
  IBlocksListItem,
  IInfoConfig,
  IMainBlockConfig,
  IOurWorkersConfig,
  IPhotoGallery,
  IReviewsGroup,
  TBlockKey,
  TBlockList,
  TEditBlockName,
  TPortfolioConfig,
  TServicesConfig,
  TTemplate,
} from '@/sharedLib';
import { ILandingConfig } from '../types/landingConfig';
import { ILandingCoreConfig, TConfirmPopup } from '../types/landingCreConfig';

type TConfigState = {
  config?: ILandingConfig;
  coreConfig: ILandingCoreConfig;
  editConfig?: ILandingConfig;
  reviews?: {
    google?: IReviewsGroup;
    yelp?: IReviewsGroup;
  };
};

type TConfigAction = {
  updateConfig: (config: ILandingConfig) => void;
  updateEditConfig: (newConfig: ILandingConfig) => void;
  updateMainBlockEditConfig: (mainBlockConfig: Partial<IMainBlockConfig>) => void;
  resetEditConfig: () => void;
  changeEditStatus: (name?: TEditBlockName) => void;
  removeBlock: (name: string, index?: number) => void;
  changeBlockPosition: (name: string, direction: number, index?: number) => void;
  addBlock: (item: Pick<IBlocksListItem, 'key' | 'type'>, position: number) => void;
  updateServicesEditConfig: (servicesConfig: TServicesConfig) => void;
  updatePortfolioEditConfig: (portfolioEditConfig: TPortfolioConfig) => void;
  updateTemplate: (templateConfig: Partial<TTemplate>) => void;
  updateInfoConfig: (index: number, infoConfig: Partial<IInfoConfig>) => void;
  updatePhotoGalleryConfig: (index: number, photoGalleryConfig: Partial<IPhotoGallery>) => void;
  updateOurWorkersBlock: (ourWorkersEditConfig: IOurWorkersConfig) => void;
  updateIsMobile: (isMobile: boolean) => void;
  openConfirmPopup: (confirmPopup: TConfirmPopup) => void;
  closeConfirmPopup: () => void;
};

export type TFullConfigState = TConfigState & TConfigAction;

const getMultiBlockIndex = (blockList: TBlockList, key: TBlockKey) => {
  const items = blockList.filter((e) => e.key === key);

  if (!items.length) return 0;

  const maxIndex = Math.max(...items.map((item) => item.index || 0));
  const allIndexes = Array.from({ length: maxIndex + 1 }, (_, i) => i);
  const actualIndexes = items.map((item) => item.index);
  const missingIndex = allIndexes.find((index) => !actualIndexes.includes(index));
  const index = missingIndex !== undefined ? missingIndex : maxIndex + 1;
  return index || 0;
};

export const createConfigStore = (initStore: TConfigState) => {
  return createStore<TFullConfigState>()((set) => ({
    ...initStore,
    updateConfig: (config) =>
      set((state) => ({
        editConfig: { ...(state.editConfig && config) },
        config: { ...(state.config && config) },
      })),
    updateEditConfig: (newConfig) =>
      set((state) => ({ editConfig: { ...state.editConfig, ...newConfig } })),
    updateMainBlockEditConfig: (mainBlockConfig) =>
      set((state) => ({
        editConfig: {
          ...state.editConfig,
          mainBlock: { ...state.editConfig?.mainBlock, ...mainBlockConfig },
        },
      })),
    updateTemplate: (templateConfig) =>
      set((state) => ({
        editConfig: {
          ...state.editConfig,
          templates: { ...state?.editConfig?.templates, ...templateConfig },
        },
      })),
    resetEditConfig: () => set((state) => ({ editConfig: { ...state.config } })),
    changeEditStatus: (name) =>
      set((state) => ({
        coreConfig: {
          ...state.coreConfig,
          editModalOpen: name || null,
        },
      })),
    removeBlock: (name, index) =>
      set((state) => ({
        editConfig: {
          ...state.editConfig,
          blocksList: (state.editConfig?.blocksList || []).filter((item) => {
            if (item.key !== name) {
              return true;
            }

            return !(item.key === name && typeof item.index === 'number' && item.index === index);
          }),
        },
      })),
    addBlock: (item, position) =>
      set((state) => {
        const blocksList = [...(state?.editConfig?.blocksList || [])];

        const index =
          item.type === 'individual'
            ? 0
            : getMultiBlockIndex(state?.editConfig?.blocksList || [], item.key);

        blocksList.splice(position, 0, {
          ...item,
          index,
        });

        return {
          editConfig: {
            ...state.editConfig,
            blocksList,
          },
        };
      }),
    changeBlockPosition: (name, direction, index) =>
      set((state) => {
        const list = [...(state?.editConfig?.blocksList || [])];

        const targetIndex = list.findIndex(
          (item) => item.key === name && (typeof item.index !== 'number' || item.index === index),
        );

        if (targetIndex !== -1) {
          let newIndex = targetIndex + direction;

          if (newIndex < 0) {
            newIndex = 0;
          } else if (newIndex >= list.length) {
            newIndex = list.length - 1;
          }
          const [removed] = list.splice(targetIndex, 1);
          list.splice(newIndex, 0, removed);
        }

        return {
          editConfig: {
            ...state.editConfig,
            blocksList: list,
          },
        };
      }),
    updateServicesEditConfig: (services) =>
      set((state) => ({
        editConfig: {
          ...state.editConfig,
          services,
        },
      })),
    updatePhotoGalleryConfig: (index, photoGalleryConfig) =>
      set((state) => {
        const photoGallery = [...(state.editConfig?.photoGallery || [])];

        photoGallery[index] = { ...photoGallery?.[index], ...photoGalleryConfig };

        return {
          editConfig: {
            ...state.editConfig,
            photoGallery,
          },
        };
      }),
    updateInfoConfig: (index, infoConfig) =>
      set((state) => {
        const info = [...(state.editConfig?.info || [])];

        info[index] = { ...info?.[index], ...infoConfig };

        return {
          editConfig: {
            ...state.editConfig,
            info,
          },
        };
      }),
    updateOurWorkersBlock: (ourWorkersEditConfig) =>
      set((state) => ({
        editConfig: {
          ...state.editConfig,
          ourWorkers: {
            ...state.editConfig?.ourWorkers,
            ...ourWorkersEditConfig,
          },
        },
      })),
    updatePortfolioEditConfig: (portfolioEditConfig) =>
      set((state) => ({
        editConfig: {
          ...state.editConfig,
          portfolio: portfolioEditConfig,
        },
      })),
    updateIsMobile: (isMobile) =>
      set((state) =>
        isMobile !== state.coreConfig.isMobile
          ? { coreConfig: { ...state.coreConfig, isMobile } }
          : state,
      ),
    openConfirmPopup: (confirmPopup) =>
      set((state) => ({ coreConfig: { ...state.coreConfig, confirmPopup } })),
    closeConfirmPopup: () =>
      set((state) => ({ coreConfig: { ...state.coreConfig, confirmPopup: null } })),
  }));
};

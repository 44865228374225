import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  checkboxAnatomy.keys,
);

const baseStyle = definePartsStyle({
  container: {
    width: 'full',
  },
  label: {
    width: 'full',
  },
});

export default defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    colorScheme: 'primary',
  },
});

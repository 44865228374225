import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

export default defineMultiStyleConfig({
  defaultProps: {
    //@ts-ignore
    focusBorderColor: 'secondary.medium',
  },
});

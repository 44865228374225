import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { ComponentDefaultProps } from '@chakra-ui/theme';

const defaultProps = {
  colorScheme: 'primary',
} satisfies ComponentDefaultProps;

const whiteVariant = defineStyle({
  color: `white`,
  _hover: {
    color: 'primary.light',
  },
  _active: {
    color: 'primary.medium',
  },
});

const linkVariant = defineStyle({
  _hover: {
    textDecoration: 'none',
    opacity: 0.8,
  },
  _active: { textDecoration: 'none' },
});

const blurVariant = defineStyle((style) => {
  const { colorScheme } = style;

  return {
    _hover: {
      bg: 'bg.pure',
      color: `${colorScheme}.dark`,
    },
    backdropFilter: 'blur(15px)',
    bg: 'rgba(255, 255, 255, 0.7)',
    color: `${colorScheme}.base`,
  };
});

const buttonTheme = defineStyleConfig({
  defaultProps,
  variants: { white: whiteVariant, link: linkVariant, blur: blurVariant },
});

export default buttonTheme;

import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  fontFamily: 'inherit',
  fontWeight: 'semibold',
});

const homeBlock = defineStyle({
  fontSize: { base: '3xl', sm: '5xl' },
  color: 'secondary.base',
});

const headingTheme = defineStyleConfig({
  baseStyle,
  variants: { homeBlock },
});

export default headingTheme;

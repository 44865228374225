const debounce = (callback: (...args: any) => void, delay = 1000) => {
  let timerId: ReturnType<typeof setTimeout> | undefined;
  return (...args: any) => {
    return new Promise((resolve, reject) => {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(async () => {
        try {
          const result = await callback(...args);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      }, delay);
    });
  };
};

export default debounce;

import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle((props) => {
  const { colorScheme } = props;

  return {
    _hover: {
      textDecoration: 'none',
      color: 'primary.medium',
    },
  };
});

const variants = defineStyle({
  headerLink: {
    color: 'secondary.base',
    textTransform: 'uppercase',
    height: 16,
    p: 5,
    _hover: {
      bg: 'bg.light',
      color: 'primary.dark',
      textDecoration: 'underline',
    },
  },
  burgerLink: {
    color: 'secondary.base',
    textTransform: 'uppercase',
    fontWeight: 'semibold',
    fontSize: 'lg',
  },
});

const linkTheme = defineStyleConfig({
  baseStyle,
  variants,
});

export default linkTheme;

export const themeRadiuses = {
  disable: {
    none: '0',
    sm: '0',
    base: '0',
    md: '0',
    lg: '0',
    xl: '0',
    '2xl': '0',
    '3xl': '0',
    full: '9999px',
  },
  light: {
    none: '0',
    sm: '0.125rem',
    base: '0.25rem',
    md: '0.375rem',
    lg: '0.5rem',
    xl: '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    full: '9999px',
  },
  medium: {
    none: '0',
    sm: '0.5rem',
    base: '0.75rem',
    md: '1rem',
    lg: '1.25rem',
    xl: '1.5rem',
    '2xl': '1.75rem',
    '3xl': '2rem',
    full: '9999px',
  },
  extra: {
    none: '0',
    sm: '0.75rem',
    base: '1rem',
    md: '1.5rem',
    lg: '1.75rem',
    xl: '2rem',
    '2xl': '2.5rem',
    '3xl': '3rem',
    full: '9999px',
  },
};

export type TRadius = keyof typeof themeRadiuses;
